<template>
  <div>
    <div v-if="data.length">
      <div class="card-content">
        <div class="columns is-multiline">
          <div
            v-for="item in data"
            :key="item"
            class="column is-6-tablet radio-tick"
          >
            <checkbox name="values" v-model="selection" :value="item">
              <span class="is-capitalized">{{ item }}</span>
            </checkbox>
          </div>
        </div>
      </div>
      <div class="card-footer has-background-white">
        <div class="column is-12">
          <div class="columns is-mobile">
            <div class="column">
              <a v-if="itemCount" class="has-text-danger" @click="clearFilters">
                Reset
              </a>
              <p v-else class="has-text-grey-light">Reset</p>
            </div>
            <div class="column is-narrow">
              <p v-if="selection.length">{{ itemCount }} selected</p>
              <p v-else class="has-text-grey-light">No items selected</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-content">
      <p>No available filters</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'StockviewCheckboxFilter',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      required: true
    }
  },
  data: () => ({ selection: [] }),
  watch: {
    selection: 'setFilterState',
    filter: 'setCheckBoxes'
  },
  mounted() {
    this.setCheckBoxes()
  },
  computed: {
    ...mapGetters('stockview/listings', ['filters']),
    itemCount() {
      const count = this.selection.length
      return count === 1 ? '1 item' : `${count} items`
    },
    type() {
      return this.filter.key
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['updateFilters']),
    track() {
      const { type, selection, filters } = this
      const selected = selection.join(',').length
      const current = filters[type] ? filters[type].length : 0
      const action = !selected ? 'clear' : selected > current ? 'add' : 'remove'
      this.$mxp.track(`stockview_stock_${action}_${type}_filter`)
    },
    async setFilterState() {
      const { type, selection } = this
      this.track()
      this.updateFilters({ filters: [{ type, payload: selection }], count: selection.length, type })
    },
    clearFilters() {
      this.selection = []
    },
    setCheckBoxes() {
      const { filters, filter } = this
      if (filters[filter.key]) this.selection = filters[filter.key]
    }
  }
}
</script>

<style lang="sass" scoped>
.card-content
  min-width: 450px
  @media (max-width: 768px)
    min-width: auto
</style>
